<template>
  <div id="login_page" class="login-page">

  </div>
</template>

<script>
export default {
  name: "login",
  data() {
    return {
      fullscreenLoading: false,
      loading: null,
    };
  },
  created() {
    console.log("provinceCAS")

    this.loading = this.$loading({
      lock: true,
      text: 'Loading',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    });
    this.checkSSO();
  },
  mounted() {},
  methods: {
    getMenu(bridgeId){
      this.$http.get('/bridge/sys/menu/tree/bri',{params:{id:bridgeId}}).then(res => {
        if (res.success){
          localStorage.setItem('menuList',JSON.stringify(res.data));
          this.$router.replace(this.$DEFAULT_URL);
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 获取window.location参数
    getAllUrlParams() {
      // 获取完整的URL
      var fullUrl = window.location.href;

      // 使用正则表达式提取查询参数部分
      var match = fullUrl.match(/\?(.*)/);

      // 检查是否匹配到查询参数
      if (match) {
        var queryString = match[1];
        var params = queryString.split("&");
        var queryParams = {};

        for (var i = 0; i < params.length; i++) {
          var param = params[i].split("=");
          var key = decodeURIComponent(param[0]);
          var value = decodeURIComponent(param[1] || "");

          queryParams[key] = value;
        }

        return queryParams;
      } else {
        console.log('当前URL没有查询参数。');
        return {};
      }
    },
    // cas登录
    checkSSO(){
      let self = this;
      //校验
      let params = this.getAllUrlParams();
      console.log(params)
      this.$http.get("/bridge/sso/ssoLogin",{
        params:{
          token: params.hbPlatToken
        }
      }).then(res=>{
        if (res.code === 200) {
          let data = res.data;
          let baseUrl = data.bridgeList[0].shorthand;
          localStorage.setItem('bridge-token', data.token);
          localStorage.setItem('baseUrl', baseUrl);
          let bridgeList = [];
          for (let i = 0; i < data.bridgeList.length; i++) {
            bridgeList.push({
              id: data.bridgeList[i].id,
              name: data.bridgeList[i].name,
              baseUrl: data.bridgeList[i].shorthand
            })
          }
          localStorage.setItem('userInfo',JSON.stringify(res.data));
          localStorage.setItem('bridgeList',JSON.stringify(bridgeList));
          self.__proto__.__proto__.$BRIDGE_NAME_LIST = bridgeList;
          self.__proto__.__proto__.$MQURL = "ws://"+window.location.host+"/mqsocket/" + baseUrl + "/";
          if (data.isProvince) {
            this.$router.replace("/platform/new");
          } else {
            // this.$router.replace(this.$DEFAULT_URL);
            this.getMenu(data.bridgeList[0].id)
          }
        } else {
          this.$message.error('用户名或密码错误');
        }
        self.loading.close();
      });
    },
  },
};
</script>

